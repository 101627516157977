<template>
  <div
    class="zixun_phone"
    v-infinite-scroll="getData"
    infinite-scroll-distance="10"
    infinite-scroll-disabled="busy"
  >
    <!-- 导航 -->

    <!-- 面包屑 -->
    <el-row type="flex" justify="center" class="crumbs">
      <el-col >
        <el-breadcrumb separator=">">
          <el-breadcrumb-item :to="{ path: '/' }">社科视频首页</el-breadcrumb-item>
          <el-breadcrumb-item>资讯</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <div class="container">
      <el-row type="flex" justify="center">
        <el-col >
          <!-- 副标题 -->
          <ViceHeadline title="资讯" />

          <div class="phone_box">
            <el-row :gutter="$store.state.isEquipment == 'pc'?40:0" class="infinite-list">
              <el-col
                :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8"
                v-for="item in list"
                :key="item.index"
                class="infinite-list-item"
              >
                <router-link
                  target="_blank"
                  :to="{
                    path: '/xq',
                    query: {
                      qing: item.id,
                      mian: 'phozi',
                    },
                  }"
                >
                  <div class="card">
                    <div class="coverWrap">
                      <img  v-lazy="item.pub_cover" alt="" class="cover" />
                      <div class="duration">
                        <p>{{ item.TIME_LENGTH }}</p>
                      </div>
                      <img
                        src="@/assets/image/zixun/播放.png"
                        alt=""
                        class="pause"
                      />
                    </div>
                    <div class="card_content">
                      <p class="title">{{ item.SYS_TOPIC }}</p>
                      <p class="time">
                        {{ $ModuleDate(item.RECORD_TIME) }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="loading">
      <p class="busy" v-show="busy">加载中...</p>
      <p class="finish" v-show="finish">没有更多了</p>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
import ViceHeadline from "@/components/ListHeadline.vue";
import NavType from "@/components/NavType.vue";
export default {
  components: {
    ViceHeadline,
    NavType,
  },
  data() {
    return {
      list: [],
      pageNo: 1,
      busy: false,
      finish: false,
      disabled: false,
    };
  },
  methods: {
    load() {
      var slef = this;
      if (this.pageNo == 1) {
        slef.getData();
      } else {
        this.busy = true;
        setTimeout(function () {
          slef.getData();
        }, 1000);
      }
    },
    getData() {
      if(this.finish){
        return;
      }
      this.busy = true;
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 45,
          pageNo: this.pageNo,
          pageSize: 9,
        })
        .then((res) => {
          for (var item in res.result) {
            this.list.push(res.result[item]);
          }
          this.busy = false;
          if (res.result.length == 0) {
            this.finish = true;
          } else {
            this.pageNo += 1;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.zixun_phone {
  background: #f9f9f9;
  padding:0 0.5rem;

  .crumbs {
    padding-top: 1.25rem;
  }

  .container {
    .phone_box {
      img {
        width: 100%;
      }

      .card {
        margin-top: 1.5rem;
        // margin-bottom: 1rem;
        background: #ffffff;
        box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
        position: relative;
        cursor: pointer;

        .cover {
          width: 100%;
          aspect-ratio:4/3;
        }

        .duration {
          margin-top: -2.9375rem;

          p {
            font-size: 1rem;
            font-weight: 400;
            color: #ffffff;
            line-height: 3rem;
            text-align: right;
            padding-right: 1.25rem;
            white-space: nowrap;
          }
        }
        .coverWrap {
          position: relative;
        }
        .pause {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 6rem;
        }

        .card_content {
          padding: 1rem;

          .title {
            font-size: 1.25rem;
            font-weight: bold;
            color: #212121;
            line-height: 2.25rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .time {
            font-size: 1rem;
            font-weight: 400;
            color: #666666;
            line-height: 2rem;
          }
        }
      }
    }
  }

  .loading {
    margin: 0.5rem 0;

    p {
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      color: #5e6d82;
    }
  }
}
</style>